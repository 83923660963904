<template>
  <div data-component-name="SeeYouSoon" data-id="seeYouSoon">
    <strong>{{ $t('Modals.SeeYouSoon.strong') }}</strong>
    <p>{{ $t('Modals.SeeYouSoon.p') }}</p>

    <Buttons.Link :to="localePath('/')">
      {{ $t('Visit_Home_Page') }}
      <SvgArrowRightUp />
    </Buttons.Link>
  </div>
</template>

<script setup lang="ts">
import { useLocalePath } from '#i18n';
import Buttons from '~/components/redesign/Buttons';

const localePath = useLocalePath();
</script>

<style scoped lang="scss">
@import "$/mixins/typography";
@import "$/mixins/flex";

[data-component-name="SeeYouSoon"] {
  @include flex-center-center;
  flex-direction: column;
  gap: 1.5rem;

  text-align: center;

  strong {
    @include subtitle-1;
  }

  p {
    @include body-3;
  }
}
</style>
